import defaultHelpGuide from 'assets/images/default_help_guide.png';
import { Link, generatePath } from 'react-router-dom';
import { CpdCardContainer } from 'teach/styles';
import { ROUTES } from 'utils/values';

import { Box, CardActionArea } from '@mui/material';

const CpdCard = ({ article }) => (
  <CpdCardContainer elevation={3}>
    <CardActionArea
      LinkComponent={Link}
      to={generatePath(ROUTES.SUPPORT_ARTICLE, { id: article.id })}
      sx={{ position: 'relative', zIndex: 1 }}
    >
      <Box src={article.image || defaultHelpGuide} component="img" alt={article.title} />
    </CardActionArea>
  </CpdCardContainer>
);

export default CpdCard;
