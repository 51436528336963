import { Box, Card, IconButton, Stack, Tab, Typography, styled } from '@mui/material';

export const GenTeachTab = styled(Tab)(() => ({
  textTransform: 'none',
  '&.Mui-selected': {
    backgroundColor: '#1F4C6E',
  },
}));

export const AdCardContainer = styled(Box, { shouldForwardProp: (props) => props !== 'src' })`
  background: linear-gradient(90deg, #142241 0%, rgba(0, 0, 0, 0) 100%), url(${(props) => props.src});
  background-size: cover;
  background-position: right;
  background-repeat: no-repeat;
`;

export const ExpandMore = styled((props) => {
  const { expand, ...other } = props;
  return <IconButton {...other} />;
})(({ theme, expand }) => ({
  transform: !expand ? 'rotate(0deg)' : 'rotate(180deg)',
  transition: theme.transitions.create('transform', {
    duration: theme.transitions.duration.shortest,
  }),
}));

export const CpdCardContainer = styled(Card)`
  position: relative;

  & .MuiCardContent-root:last-child {
    padding-bottom: 0.5rem;
  }

  & .CpdImgContainer {
    overflow: hidden;
  }

  & .MuiCardMedia-img {
    transition: all 0.4s ease-in-out;
  }

  img {
    width: 100%;
    height: auto;
    vertical-align: top;
  }

  &:hover .MuiCardMedia-img {
    transform: scale(1.2);
  }

  &::after {
    content: '';
    width: 31rem;
    height: 7.5rem;
    position: absolute;
    background: linear-gradient(180deg, rgba(255, 255, 255, 0.1) 0%, rgba(255, 255, 255, 0) 100%);
    top: 1rem;
    right: -13rem;
    transform: rotate(55deg);
    transition: all 0.6s;
    pointer-events: none;
    z-index: 2;
  }

  &:hover::after {
    right: -8rem;
  }
`;

export const CompletedTagContainer = styled(Stack)`
  position: absolute;
  top: 0.5rem;
  right: 0.5rem;
  z-index: 1;
`;

export const CompletedImgGradient = styled(Box)`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.66);
`;

export const Image = styled('img')`
  display: block;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  width: 100%;
  object-fit: cover;
`;

export const StyledContentBox = styled(Typography)`
  img {
    max-width: 100%;
    height: auto;
  }
`;
export const StyledBox = styled(Box)`
  position: relative;
`;
